/**
 * putting this in a separate file so we can "defer" loading it
 */
import Alpine from "alpinejs"
import collapse from "@alpinejs/collapse"
import anchor from "@alpinejs/anchor"
import persist from "@alpinejs/persist"
import intersect from "@alpinejs/intersect"
import focus from "@alpinejs/focus"
import tippy from "tippy.js"
import "tippy.js/dist/tippy.css"

// Directive: x-tooltip
// Usage: <button x-tooltip="Hello world">Hover me</button>
// Options:
// - modifiers.media - show just for specific media query
// - modifiers.media[sm|md|lg|xl|2xl|number][up|down]
Alpine.directive("tooltip", (el, { modifiers, expression }) => {
	let options = { content: expression }

	if (modifiers.includes("allowhtml")) {
		options.allowHTML = true
	}

	let delayIndex = modifiers.indexOf("delay")
	if (delayIndex >= 0 && modifiers[delayIndex + 1] !== undefined && !isNaN(parseInt(modifiers[delayIndex + 1]))) {
		let showDelay = parseInt(modifiers[delayIndex + 1])
		let hideDelay = showDelay

		if (modifiers[delayIndex + 2] !== undefined && !isNaN(parseInt(modifiers[delayIndex + 2]))) {
			hideDelay = parseInt(modifiers[delayIndex + 2])
		}

		options.delay = [showDelay, hideDelay]
	}

	const instance = tippy(el, options)

	const mediaIndex = modifiers.indexOf("media")
	if (mediaIndex !== -1) {
		const [breakpoint, direction = "down"] = modifiers.splice(mediaIndex + 1, 2)

		const breakpoints = {
			sm: 640,
			md: 768,
			lg: 1024,
			xl: 1280,
			"2xl": 1536,
		}

		const directions = {
			up: "min-width",
			down: "max-width",
		}

		const mediaBreakpoint = breakpoints[breakpoint] || parseInt(breakpoint)

		if (isNaN(mediaBreakpoint)) {
			console.error("Invalid breakpoint:", breakpoint)
			return
		}

		const mediaDirection = directions[direction]

		if (!mediaDirection) {
			console.error("Invalid direction:", direction)
			return
		}

		const mediaQuery = `(${mediaDirection}: ${mediaBreakpoint}px)`

		const handleShow = (e) => {
			if (media.matches) {
				instance.enable()
			} else {
				instance.disable()
			}
		}

		const media = window.matchMedia(mediaQuery)
		media.addEventListener("change", handleShow)

		handleShow(media)
	}
})

Alpine.directive("portal", (el, { modifiers, expression }, { Alpine, cleanup: cleanup2 }) => {
	if (el.tagName.toLowerCase() !== "template") console.warn("x-portal can only be used on a <template> tag", el)
	let target = getTarget(el, expression)
	if (!target || !(target instanceof HTMLElement)) {
		console.warn("x-portal value should be an HTML element", el)
		return
	}
	let clone2 = el.content.cloneNode(true).firstElementChild
	el._x_teleport = clone2
	clone2._x_teleportBack = el
	el.setAttribute("data-teleport-template", true)
	clone2.setAttribute("data-teleport-target", true)
	if (el._x_forwardEvents) {
		el._x_forwardEvents.forEach((eventName) => {
			clone2.addEventListener(eventName, (e) => {
				e.stopPropagation()
				el.dispatchEvent(new e.constructor(e.type, e))
			})
		})
	}
	Alpine.addScopeToNode(clone2, {}, el)
	let placeInDom = (clone3, target2, modifiers2) => {
		if (modifiers2.includes("prepend")) {
			target2.parentNode.insertBefore(clone3, target2)
		} else if (modifiers2.includes("append")) {
			target2.parentNode.insertBefore(clone3, target2.nextSibling)
		} else {
			target2.appendChild(clone3)
		}
	}
	Alpine.mutateDom(() => {
		placeInDom(clone2, target, modifiers)
		Alpine.skipDuringClone(() => {
			Alpine.initTree(clone2)
			clone2._x_ignore = true
		})()
	})
	el._x_teleportPutBack = () => {
		let target2 = el.closest("section.field").querySelector(value)
		Alpine.mutateDom(() => {
			placeInDom(el._x_teleport, target2, modifiers)
		})
	}
	cleanup2(() => clone2.remove())
})

Alpine.directive("insert-template", (el, { modifiers, expression }, { Alpine, cleanup }) => {
	// Evaluate the expression to get the template element
	let templateElement = getTarget(el, expression)

	// Ensure that the expression returns a template element
	if (!(templateElement instanceof HTMLTemplateElement)) {
		console.warn("x-insert-template value should be a template element", el)
		return
	}

	// Clone the content of the template element
	let clone = templateElement.content.cloneNode(true).firstElementChild

	// Add scope to the cloned content if needed
	Alpine.addScopeToNode(clone, {}, el)

	// Insert the cloned content into the element
	let insertContent = (clone, target, modifiers) => {
		if (modifiers.includes("prepend")) {
			target.prepend(clone)
		} else if (modifiers.includes("append")) {
			target.append(clone)
		} else {
			target.innerHTML = "" // Clear existing content
			target.append(clone)
		}
	}

	Alpine.mutateDom(() => {
		insertContent(clone, el, modifiers)

		// Initialize the Alpine components within the cloned content
		Alpine.skipDuringClone(() => {
			Alpine.initTree(clone)
			clone._x_ignore = true
		})()
	})

	// Cleanup function to remove the inserted content when Alpine context is destroyed
	cleanup(() => clone.remove())
})

const teleportContainerDuringClone = document.createElement("div")

function getTarget(el, expression) {
	let target = Alpine.skipDuringClone(
		() => {
			return Alpine.evaluate(el, expression)
		},
		() => {
			return teleportContainerDuringClone
		}
	)()
	if (!target) console.warn(`Cannot find x-teleport element for selector: "${expression}"`)
	return target
}

window.Alpine = Alpine

Alpine.plugin(collapse)
Alpine.plugin(anchor)
Alpine.plugin(persist)
Alpine.plugin(intersect)
Alpine.plugin(focus)

Alpine.start()
